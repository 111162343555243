<template>
  <div class="wrapper">

    <!--     订单详情-->
    <headBack>
      <template v-slot:title>
        <div class='title'>
           订单详情
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class="list">
      <div class="item">
        <div class="name">
          <span class="tit">{{detail.PRODUCT_NAME}}</span>
          <span class="sta">{{ setStates(detail) }}</span>
        </div>
      </div>
      <div class="item">
        <div class="names">
          <span class="tit">姓名</span>
          <span class="sta">{{detail.USER_NAME}}</span>
        </div>
        <div class="names">
          <span class="tit">手机号</span>
          <span class="sta">{{detail.USER_MOBILE}}</span>
        </div>
      </div>
      <div class="item">
        <div class="l">
          <span class="tit">服务费</span>
          <span class="stas">{{detail.PRICE}}元</span>
        </div>
        <div class="l">
          <span class="tit">代金券</span>
          <span class="stas">{{detail.COUPON_PRICE}}元</span>
        </div>
        <div class="l">
          <span class="tit">实付金额</span>
          <span class="stas">{{detail.REAL_PAY_PRICE}}元</span>
        </div>
        <div class="l">
          <span class="tit">预约号</span>
          <span class="stas">{{detail.ORDER_ID}}</span>
        </div>
      </div>
    </div>
    <div class="btn_box" v-if="flag">
      <div class="k"></div>
         <div class="btn" @click="toActions">{{ btnText}}</div>
    </div>
  </div>
</template>

<script>
import {reactive,ref} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {Toast} from "vant";
export default {
  name: "orderDetail",
  setup(){

    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const list = ref('')
    const detail = ref('')
    const getList =()=> {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityOrderInfo",
        orderId:route.query.orderId,
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {
        console.log(res)
        detail.value = res.data;

      })
    }
    getList();


    // state
    const states = ref('');
    const flag = ref(false);
    const btnText = ref('');

    const setStates = (detail) => {
      if(detail.ORDERSTATUS == -1){
        states.value = '超时取消'
        return '超时取消'
      }
      if(detail.ORDERSTATUS == 0 && detail.PAYSTATUS ==0){
        states.value = '待付款';
        btnText.value = '去支付';
        flag.value = true;
        return '待付款'
      }
      if(detail.ORDERSTATUS == 0 && detail.PAYSTATUS ==1){
        states.value = '已完成'
        flag.value = true;
        btnText.value = '申请退款';
        return '已完成'
      }
      if(detail.ORDERSTATUS == 2 && detail.PAYSTATUS ==0){
        states.value = '已取消'
        return '已取消'
      }
      if(detail.ORDERSTATUS == 3 ){
        states.value = '退款中'
        return '退款中'
      }
      if(detail.ORDERSTATUS == 7 && detail.PAYSTATUS ==2){
        states.value = '已退款'
        return '已退款'
      }

    }

    const toDetail =(item)=> {
      router.push({
        name:'communityOrderDetail',
        query:{
          productId:item.PRODUCT_ID
        }
      })
    }

    //去支付  //去退款
    const toActions = () => {
      if(btnText.value == '去支付'){
        router.push({
          name:'communityPay',
          query:{
            fee:detail.value.PRICE,
            orderId:detail.value.ORDER_ID,
            productId:detail.value.PRODUCT_ID
          }
        })
      }
      if(btnText.value == '申请退款'){
        let formData = new FormData();
        formData.append('data', JSON.stringify({
          function: "activityOrderApplyRefund",
          orderId:detail.value.ORDER_ID,
          newversion: '2120'
        }))
        formData.append('encryption', false)

        homeApi.getList(formData).then((res) => {
          console.log(res)
          detail.value = res.data;
          if(res.code == '0000'){
            btnText.value = '退款中...'
            router.push({
              name:'communityOrderList',
              query:{

              }
            })
          }

        })
      }
    }
    return {
      list,
      detail,
      flag,
      btnText,
      toDetail,
      setStates,
      toActions
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  background-color: #f2f4f5;
  min-height: 100vh;
}
.list{
  box-sizing: border-box;

  .item{
    color:#323232;
    width: 94%;
    margin:20px auto;
    background: #fff;
    border-radius: 16px;
    box-sizing: border-box;
    padding:20px;
    font-size: 30px;
    .names{
      font-size: 28px;
      .tit{
        width:140px;
        color:#999999;
        display: inline-block;
        margin-bottom: 10px;

      }
    }
    .names:last-child{
      margin-top: 0;
    }
    .l{
      display: flex;
      font-size: 28px;
      justify-content: space-between;
      margin-bottom: 10px;
      .tit{
        color:#999;
      }
    }
    .l:last-child{
      margin: 0;
    }
    .name{
      display: flex;
      justify-content: space-between;
      .tit{

      }
      .sta{
        color:#999999;
        font-size: 26px;
      }
    }
    .sub{
      display: flex;
      align-items: center;
      color:#666666;
      height:50px;
      font-size: 28px;
      img{
        width: 36px;
        margin-right: 10px;
      }
    }
  }
}
.btn_box{
  position: fixed;
  left: 0;
  bottom:0;
  width: 100%;
  height: 110px;
  background-color: #FFFFFF;
    display: flex;
  align-items: center;
  justify-content: space-between;
  .k{
    width: 40%;
    height: 100px;
  }
  .btn{
    width: 160px;
    padding:8px 30px;
    font-size: 32px;
    color:#00c291;
    border:1px solid #00c291;
    text-align: center;
    border-radius: 40px;
    margin-right: 60px;
  }
}
</style>
